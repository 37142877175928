// IE polyfill for NodeList.forEach

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, argument) {
    argument = argument || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(argument, this[i], i, this);
    }
  };
}

(function ($) {
  let nav = (header) => {
    window.addEventListener('scroll', () => {
      let position = window.pageYOffset;

      if (position > 50) {
        header.addClass('fixed');
        $('body').addClass('fix');
        //$('#logo-horizontal').show();
        //$('#logo-vertical').hide();
      } else {
        header.removeClass('fixed');
        $('body').removeClass('fix');
        //$('#logo-horizontal').hide();
        //$('#logo-vertical').show();
      }
    });

    $('.menu-toggle').click(function () {
      $(this).toggleClass('open');
      $('body').toggleClass('menu-opened');
      $('.nav').slideToggle(200);
    });
  };

  let house = () => {
    /*
    $('.house__gallery-items').slick({
      dots: false,
      slidesPerRow: 4,
      rows: 2,
      prevArrow: $('.house__gallery .slider__arrow--prev'),
      nextArrow: $('.house__gallery .slider__arrow--next'),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    */

    $('.house__pictures').slick({
      slidesPerRow: 1,
      arrows: false,
      adaptiveHeight: true,
      mobileFirst: true,
      dots: true,
      responsive: [
        {
          breakpoint: 992,
          settings: "unslick",
        },
      ],
    });
  };

  let post = () => {
    $('.single-post .slider__slides').slick({
      infinite: false,
      slidesToShow: 5,
      vertical: true,
      prevArrow: $('.posts-list .slider__arrow--prev'),
      nextArrow: $('.posts-list .slider__arrow--next'),
    });

    let maxHeight = -1;

    $('.single-post .slider__slides .slick-slide').each(function () {
      if ($(this).height() > maxHeight) {
        maxHeight = $(this).height();
      }
    });

    $('.single-post .slider__slides .slick-slide').each(function () {
      if ($(this).height() < maxHeight) {
        $(this).css('margin', Math.ceil((maxHeight - $(this).height()) / 2) + 'px 0');
      }
    });
  };

  function home() {
    var promo = $('.promo');

    $('.promo__button').click(function () {
      promo.toggleClass('active');
    });

    $('.close-promo').click(function () {
      promo.removeClass('active');
    });
  }

  function isMobile() {
    return $('.nav__toggle').is(':visible');
  }

  function scrollTo(target) {
    $('html, body').animate({
        scrollTop: target.offset().top - $('.site__header').height(),
    }, 300);
  }

  const header = $('.site__header');

  nav(header);
  house();
  post();
  home();

  $(window).trigger('scroll');

  $(window).load(function () {
    if (window.location.hash) {
      var target = $(window.location.hash);
      setTimeout(function () {
        scrollTo(target);
        showAttractionBox(window.location.hash);
      }, 400);
    }
    setIco();
  });

  function contactFormFocus(el) {
    if ($.trim(el.val()).length > 0) {
      el.parents('.form__field').addClass('active');
    } else {
      el.parents('.form__field').removeClass('active');
    }
  }

  $('input:not([type="submit"]), textarea')
    .each(function () {
      contactFormFocus($(this));
    })
    .focus(function () {
      $(this).parents('.form__field').addClass('active');
    })
    .blur(function () {
      contactFormFocus($(this));
    });

  function setIco() {
    var el = $('.owl-item.active');
    el.removeClass('owl-main-ico');
    var ell = el.length;
    el.each(function (i, element) {
      if (i < ell - 1) {
        $(this).addClass('owl-main-ico');
      }
    });
  }
})(jQuery);
